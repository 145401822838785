// src/components/Header.js
import React from 'react';
import { NavLink} from 'react-router-dom'
import manvec from '../assets/manvec.png'
import treadmill from '../assets/treadmill.jpg'
import { MoveRight } from 'lucide-react'
// import wavehaikei from '../assets/wavehaikei.svg'

const HeroHeader = () => {
  const handleCalendlyPopup = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/jordanstaffordcoaching' });
    return false; // Prevent default action
  };
  return (
    <header className="heroheader py-24">
      <div class="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">

        <div className='herotext px-6 md:px-0 text-left justify-center'>
          <div class="split-content home-hero-content zebra pt-0 sm:pt-12 lg:pt-0">
              <div class="subtitle text-blue-700 text-xl whitespace-nowrap mb-4 md:mb-8">JORDAN STAFFORD, COACH</div>
              <h1  class="title heroh1 home-hero text-5xl md:text-6xl mb-4 md:mb-8">Reach Your Potential</h1>
              <p  class="paragraph home-hero mb-8">
                Transform your fitness journey with personalized coaching. 
                Whether you're just starting out or looking to take your training further, 
                with my tailored guidance and support to reach your goals you will be unstoppable!
              </p>
            
            <div  class="md:flex flex-col md:flex-row items-center md:space-x-8">
              <a onClick={handleCalendlyPopup} class="mb-2 md:mb-0 transition duration-200 ease-in-out border border-black hover:border-blue-700 bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full">
                BOOK A CALL
              </a>
              <a href="#programs" class="mb-8 md:mb-0 transition duration-200 ease-in-out border border-black hover:border-blue-700 text-blCK hover:bg-blue-700 flex hover:text-white text-md tester px-6 py-4 rounded-full">
                PROGRAMS
              </a>
            </div>

          </div>
        </div>

        <img className='heroimage px-8 md:px-0' src={treadmill}></img>

      </div>        
    </header>


  );
};

export default HeroHeader;
