// src/components/Header.js
import React, {useState} from 'react';


const FaqSection = () => {

  const handleCalendlyPopup = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/jordanstaffordcoaching' });
    return false; // Prevent default action
  };


  const [openFAQ, setOpenFAQ] = useState([]);

  // Function to handle opening and closing FAQ items
  // const toggleFAQ = (index) => {
  //   setOpenFAQ(openFAQ === index ? null : index);
  // };
  const toggleFAQ = (index) => {
    if (openFAQ.includes(index)) {
      // If the index is already in the array, remove it (close the FAQ)
      setOpenFAQ(openFAQ.filter((i) => i !== index));
    } else {
      // If the index is not in the array, add it (open the FAQ)
      setOpenFAQ([...openFAQ, index]);
    }
  };

  return (
    <div id='faq' class="max-w-7xl mx-auto pt-12 pb-36">
      <div class="flex flex-col md:flex-row  justify-between px-6 md:px-0">

        <div class="w-full md:w-1/3 mr-16 flex flex-col justify-center mb-8 md:mb-0">
          <div class="flex flex-col text-left">
            <h2 class="text-5xl mb-8">FAQs</h2>
            <p class="paragraph-small faqs text-gray-700 mb-8">
              If your question isn't answered here then book a free call with me or send me a message on instagram directly! 
              I'm here to help you directly with any questions or concerns you might have.
              There is no such thing as a stupid question so just ask!
            </p>
            <div className='flex w-full '>
              <a
              onClick={handleCalendlyPopup}
              class="w-full text-center transition duration-200 ease-in-out border border-black 
              hover:border-blue-700 bg-black hover:bg-blue-700 text-white text-md tester px-6 py-4 rounded-full">
                BOOK A CALL
              </a>
            </div>
            
          </div>
        </div>
        
    
        <div class="split-content w-full">

        <details class="border-y border-gray-200 py-8 pl-8 bg-white">
            <summary onClick={() => toggleFAQ(1)} class="flex justify-between cursor-pointer py-2 px-4 text-lg font-medium text-gray-700 hover:text-blue-600">
              Do I need to get in shape before starting with you?
              <span className="toggle-icon">{openFAQ.includes(1) ? '-' : '+'}</span>
            </summary>
            <div class="px-4 py-2 text-gray-600 text-left transition 300s ease-in-out">
              <p class="">
              You do not need to be fit before starting with me! No matter your fitness 
              level—whether you've been training for a long time or have never trained 
              at all—I am here to cater to your personal needs. As your coach, we can 
              work together to address your individual goals and create a plan that's 
              perfect for you. If you're unsure or have any questions, you can always 
              book a call and speak with me face to face. I'm here to help you every 
              step of the way!
              </p>
            </div>
          </details>


          <details class="border-b border-gray-200 py-8 pl-8 bg-white">
            <summary onClick={() => toggleFAQ(2)} class="flex justify-between cursor-pointer py-2 px-4 text-lg font-medium text-gray-700 hover:text-blue-600">
            I struggle with nutrition and eating healthy. Can you help me with that?
              <span className="toggle-icon">{openFAQ.includes(2) ? '-' : '+'}</span>
            </summary>
            <div class="px-4 py-2 text-gray-600 text-left">
              <p class="">
              Absolutely! Nutrition is a key part of any fitness journey, and I'm 
              here to guide you through it. Together, we can create a sustainable plan that works for your 
              lifestyle and preferences, making it easier to eat well without feeling overwhelmed. Whether you 
              need advice, meal ideas, or just some encouragement, I'm here to support you every step of the way.
              </p>
            </div>
          </details>

          <details class="border-b border-gray-200 py-8 pl-8 bg-white">
            <summary onClick={() => toggleFAQ(3)} class="flex text-left justify-between cursor-pointer py-2 px-4 text-lg font-medium text-gray-700 hover:text-blue-600">
              I've been training for years and am already in great shape. How can your coaching help me?
              <span className="toggle-icon">{openFAQ.includes(3) ? '-' : '+'}</span>
            </summary>
            <div class="px-4 py-2 text-gray-600 text-left">
              <p class="">
                Definitely! Even seasoned athletes can benefit from specialized coaching to overcome plateaus, 
                fine-tune their performance, and prepare for events like HYROX or other major competitions. 
                Book a call, and let's discuss your goals and how we can work together to reach them!
              </p>
              {/* I've worked with many highly trained individuals to help them achieve their next level of fitness.  */}
            </div>
          </details>

          <details class="border-b border-gray-200 py-8 pl-8 bg-white">
            <summary onClick={() => toggleFAQ(4)} class="flex justify-between cursor-pointer py-2 px-4 text-lg font-medium text-gray-700 hover:text-blue-600">
             The only thing I want to do is lose weight, can you help?
              <span className="toggle-icon">{openFAQ.includes(4) ? '-' : '+'}</span>
            </summary>
            <div class="px-4 py-2 text-gray-600 text-left">
              <p class="">
                Yes, I appreciate when people are direct! I can definitely help you lose weight, and we can tailor your diet and 
                training specifically around that goal. However, I also encourage all my clients to keep an open mind—you might 
                find yourself loving the gym and the fitness lifestyle more than you expect. All I ask is that you stay open to 
                discovering new aspects of fitness along the way!
              </p>
            </div>
          </details>

          {/* <details class="border-b border-gray-200 py-8 pl-8 bg-white">
            <summary onClick={() => toggleFAQ(5)} class="flex justify-between cursor-pointer py-2 px-4 text-lg font-medium text-gray-700 hover:text-blue-600">
            ?????????????????????????
              <span className="toggle-icon">{openFAQ.includes(5) ? '-' : '+'}</span>
            </summary>
            <div class="px-4 py-2 text-gray-600 text-left">
              <p class="">
              Absolutely! Nutrition is a key part of any fitness journey, and I'm 
              here to guide you through it. Together, we can create a sustainable plan that works for your 
              lifestyle and preferences, making it easier to eat well without feeling overwhelmed. Whether you 
              need advice, meal ideas, or just some encouragement, I'm here to support you every step of the way.
              </p>
            </div>
          </details> */}





        </div>
      </div>
    </div>

  );
};

export default FaqSection;
