import React from 'react';
import robot from '../assets/robot.png'
import simpsons from '../assets/simpsons.jpeg'
import realscreen from '../assets/realscreen.jpg'
import levelup from '../assets/levelup.png'
import befaft from '../assets/befaft.jpg'
import press from '../assets/press.jpg'
import pullup from '../assets/pullup.jpg'
import ba1 from '../assets/ba1.jpg'
import ba2 from '../assets/ba2.jpg'
import ba3 from '../assets/ba3.jpg'
import ba4 from '../assets/ba4.jpg'
import ba6 from '../assets/ba6.png'
import ba7 from '../assets/ba7.png'
import ba8 from '../assets/ba8.png'
import ba9 from '../assets/ba9.png'
import wave from '../assets/wave.png'
// import befaft from '../assets/befaft.jpg'
import befaft1 from '../assets/befaft1.jpg'
// import wavehaikei from '../assets/wavehaikei.svg'
import HeroHeader from './HeroHeader';
import onecoaching from './onecoaching';
import Coaching from './Coaching';
import { Check, Instagram } from 'lucide-react';
import FaqSection from './FaqSection';

const FrontPage = () => {
  const handleCalendlyPopup = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/jordanstaffordcoaching' });
    return false; // Prevent default action
  };
  return (
    <div className=''>
      <HeroHeader />
      
      
      <div className='results bg-grayback p-8 md:p-24'>
        
        <div className='mx-auto  grid grid-cols-1 md:grid-cols-4 gap-10'>
          
          {/* <div className=''>
            <img class="resultimage" src={ba6} />
          </div>
          <div className=''>
            <img class="resultimage" src={ba7} />
          </div>
          <div className=''>
            <img class="resultimage" src={ba8} />
          </div>
          <div className=''>
            <img class="resultimage" src={ba9} />
          </div> */}
          <div className='md:col-span-5 flex justify-center'>
            <div className='grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10'>
            <div className=''>
            <img class="resultimage" src={befaft1} />
            </div>
            {/* <div className=''>
              <img class="resultimage" src={ba1} />
            </div> */}
            <div className=''>
              <img class="resultimage" src={ba2} />
            </div>
            <div className=''>
              <img class="resultimage" src={ba3} />
            </div>
            <div className=''>
              <img class="resultimage" src={ba4} />
            </div>
              <div className=''>
                <img class="resultimage" src={ba6} />
              </div>
              <div className=''>
                <img class="resultimage" src={ba7} />
              </div>
              <div className=''>
                <img class="resultimage" src={ba8} />
              </div>
              <div className=''>
                <img class="resultimage" src={ba9} />
              </div>
            </div>
          </div>

        </div>

      </div>

{/* ---------------------------------------------------------------------------------------------------- */}
      <div id="about" className="about py-24 px-6">
        <div class="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">

          <img className='heroimage md:mr-12' src={press}></img>

          <div className='mt-6 md:mt-0 herotext text-left justify-center'>          
            <div class="split-content home-hero-content zebra">
              {/* <div  class="subtitle text-blue-700 text-xl mb-8">JORDAN STAFFO, COACH</div> */}
              <h1  class="text-5xl mb-8">Who Am I?</h1>
              <p  class="paragraph home-hero mb-8">
                My name is Jordan Stafford,
                and i am a dedicated coach passionate about helping people achieve their health and wellness goals. 
                With years of hands-on experience, I've developed a comprehensive 
                understanding of various training methods and nutritional strategies that work for everyone, 
                regardless of their starting point.
              </p>
              <p  class="paragraph home-hero mb-8">
                Fitness and strength have always been central to my life, and I've committed myself to 
                helping others find their path to physical and mental well-being through this journey. Throughout my career, 
                I've had the privilege of working with a diverse range of clients—from beginners to seasoned athletes—helping 
                them break through barriers and reach new milestones. I take pride in creating personalized plans that not 
                only achieve results but also inspire a genuine love for fitness and a continuous pursuit of self-improvement.
              </p>
              <p  class="paragraph home-hero mb-8">
                I'm committed to guiding my clients through every step of their fitness journey, providing support, 
                motivation, and expertise. Whether you're looking to lose weight, build muscle, or just feel better, 
                I'm here to help you discover the best version of yourself.
              </p>
            
            <div  class="flex flex-col md:flex-row items-center md:space-x-8">
              <a 
                onClick={handleCalendlyPopup}
              class="w-full justify-center md:w-auto text-center mb-2 md:mb-0 transition duration-200 ease-in-out border border-black hover:border-blue-700 bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full">
                BOOK A CALL
              </a>
              <a href="#programs" class="w-full justify-center text-center md:w-auto mb-8 md:mb-0 transition duration-200 ease-in-out border border-black hover:border-blue-700 text-blCK hover:bg-blue-700 flex hover:text-white text-md tester px-6 py-4 rounded-full">
                PROGRAMS
              </a>
            </div>

            </div>
          </div>

          
        </div>
      </div>
  

{/* ---------------------------------------------------------------------------------------------------- */}


<div id='coaching' className='md:pt-20 pb-24 px-6 bg-grayback'>
        <h2 className='text-5xl pt-16 md:pt-0 mb-16'>Coaching</h2>
        <div className='max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-6 place-content-center'>

          <div className='programimage w-full bg-white testbg py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div>
              <h2 class="text-2xl mb-2">Hyrox Prep</h2>
              <div class="text-4xl mb-6">£199.00/m <span className='text-sm'>(Subsciption)</span></div>
              <p class="text-xl text-gray-500 mb-6">
                Get race-ready with my comprehensive Hyrox Coaching Program!
              </p>
              </div>

              <div>
              <div class="mb-6">
                <div class="text-xl mb-2">What's included:</div>

                <div class="w-layout-grid plan-item-features-grid">
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Bespoke Training Plan</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Ongoing Coaching Support</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Online Coaching App</div>
                  </div>

                </div>

              </div>

              <a href="https://coach.everfit.io/package/TL924327" target="_blank" class="justify-center bg-black hover:bg-blue-700 hover:bg-blue-700 mt-4 md:mt-10 flex text-white text-md tester px-6 py-4 rounded-full">
                BUY PROGRAM
              </a>
              </div>
            </div>
          </div>

          <div className='programimage w-full bg-white testbg py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div>
                <h2 class="text-2xl mb-2">Hybrid Athlete</h2>
                <div class="text-4xl mb-6">£199.00/m <span className='text-sm'>(Subsciption)</span></div>
                <p class="text-xl text-gray-500 mb-6">Build muscle<br/> Increase Strength<br/> Improve Cardio</p>
              </div>
              
              <div>
              <div class="mb-6">
                <div class="text-xl mb-2 md:mb-4">What's included:</div>

                <div class="w-layout-grid plan-item-features-grid">
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Tailored Program</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Weekly Group Check Ins</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Program adaptations</div>
                  </div>

                </div>

              </div>

              <a href="https://coach.everfit.io/package/YV742397" target="_blank" class="justify-center bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 mt-4 md:mt-10 rounded-full">
                BUY PROGRAM
              </a>
              </div>
            </div>
          </div>

          <div className='programimage w-full testbg bg-white py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div>
                <h2 class="text-2xl mb-2">1-2-1 Coaching</h2>
                <div class="text-4xl mb-6">£199.00/m <span className='text-sm'>(Subsciption)</span></div>
                <p class="text-xl text-gray-500 mb-6">1-2-1 tailored programming and support</p>
              </div>

              <div>
                <div class="mb-6">
                  <div class="text-xl mb-2 md:mb-4">What's included:</div>

                  <div class="w-layout-grid plan-item-features-grid">
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Tailored Program</div>
                    </div>
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>24/7 Contact</div>
                    </div>
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Diet & Nutrition</div>
                    </div>
                    {/* <div class="flex space-x-4 items-center mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Advanced training help</div>
                    </div> */}
                  </div>

                </div>
                <a href="https://coach.everfit.io/package/GG587778"  target='_blank' class="justify-center mt-10 bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full pushclass">
                  BUY PROGRAM
                </a>
              </div>      
              

            </div>
          </div>
          
          <div className='programimage w-full testbg bg-white py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div>
                <h2 class="text-2xl mb-2">Program Library</h2>
                <div class="text-4xl mb-6">£59.00/m <span className='text-sm'>(Subsciption)</span></div>
                <p class="text-xl text-gray-500 mb-6">Full access to 6 different workout programs, 30 weeks total training.</p>
              </div>

              <div>
                <div class="mb-6">
                  <div class="text-xl mb-2 md:mb-4">What's included:</div>

                  <div class="w-layout-grid plan-item-features-grid">
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>6 Programs</div>
                    </div>
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Weekly Check In</div>
                    </div>
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Recipe Book</div>
                    </div>
                    {/* <div class="flex space-x-4 items-center mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Advanced training help</div>
                    </div>  */}
                  </div>

                </div>
                <a href="https://coach.everfit.io/package/PK556649"  target='_blank' class="justify-center mt-10 bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full pushclass">
                  BUY PROGRAM
                </a>
              </div>      
              

            </div>
          </div>

          <div className='programimage w-full testbg bg-white py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div>
                <h2 class="text-2xl mb-2">Workout Library</h2>
                <div class="text-4xl mb-6">£20.00/m <span className='text-sm'>(Subsciption)</span></div>
                <p class="text-xl text-gray-500 mb-6">Full access to 16 workouts: 15-min, bodyweight, AMRAPs, dumbbell, and recovery.</p>
              </div>

              <div>
                <div class="mb-6">
                  <div class="text-xl mb-2 md:mb-4">What's included:</div>

                  <div class="w-layout-grid plan-item-features-grid">
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>16 Workouts</div>
                    </div>
                    {/* <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Access to communtiy</div>
                    </div> */}
                    <div class="flex space-x-4 items-center mb-1 md:mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Recipe Book</div>
                    </div>
                    {/* <div class="flex space-x-4 items-center mb-3">
                      <Check />
                      <div className='text-xl text-gray-500'>Advanced training help</div>
                    </div>  */}
                  </div>

                </div>
                <a href="https://coach.everfit.io/package/BA285145"  target='_blank' class="justify-center mt-10 bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 rounded-full pushclass">
                  BUY PROGRAM
                </a>
              </div>      
              

            </div>
          </div>


        </div>

</div>




{/* ---------------------------------------------------------------------------------------------------- */}



{/* ---------------------------------------------------------------------------------------------------- */}      
      <div id='programs' className='pt-20 md:pt-20 pb-24 px-6 '>
        <h2 className='text-5xl mb-16'>Programs</h2>
        <div className='max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 place-content-center'>

          <div className='programimage w-full bg-grayback testbg py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div>
              <h2 class="text-2xl mb-2">Hyrox Prep</h2>
              <div class="text-4xl mb-6">£89.00</div>
              <p class="text-xl text-gray-500 mb-6">
                Get race-ready in just 8 weeks with my comprehensive Hyrox Prep Program!
              </p>
              </div>
              <div>
              <div class="mb-6">
                <div class="text-xl mb-4">What's included:</div>

                <div class="w-layout-grid plan-item-features-grid">
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>8 Week Program</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Recipe Book</div>
                  </div>
                  {/* <div class="flex space-x-4 items-center mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Weekly Group Check-ins</div>
                  </div> */}
                  {/* <div class="flex space-x-4 items-center mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Program Adaptations</div>
                  </div> */}
                  {/* <div class="flex space-x-4 items-center mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Support</div>
                  </div> */}
                </div>

              </div>
              <a href="https://coach.everfit.io/package/YF834325" target="_blank" class="justify-center bg-black hover:bg-blue-700 mt-10 flex text-white text-md tester px-6 py-4 rounded-full">
                BUY PROGRAM
              </a>
              </div>
            </div>
          </div>

          <div className='programimage w-full bg-grayback testbg py-6 md:py-12 shadow-md hover:shadow-xl'>
            <div class="text-left h-full px-6 md:px-12 flex flex-col justify-between">
              <div>
                <h2 class="text-2xl mb-2">Hybrid Athlete</h2>
                <div class="text-4xl mb-6">£89.00</div>
                <p class="text-xl text-gray-500 mb-6">Build muscle<br/> Increase Strength<br/> Improve Cardio</p>
              </div>              
              <div>
              <div class="mb-6">
                <div class="text-xl mb-4">What's included:</div>
                <div class="w-layout-grid plan-item-features-grid">
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>12 Week Program</div>
                  </div>
                  <div class="flex space-x-4 items-center mb-1 md:mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Recipe Book</div>
                  </div>
                  
                  {/* <div class="flex space-x-4 items-center mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Weekly Group Check Ins</div>
                  </div> */}
                  {/* <div class="flex space-x-4 items-center mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Program adaptations</div>
                  </div> */}
                  {/* <div class="flex space-x-4 items-center mb-3">
                    <Check />
                    <div className='text-xl text-gray-500'>Diet & Nutrition</div>
                  </div> */}
                </div>
              </div>
              <a href="https://coach.everfit.io/package/HL927927" target="_blank" class="justify-center bg-black hover:bg-blue-700 flex text-white text-md tester px-6 py-4 mt-10 rounded-full">
                BUY PROGRAM
              </a>
              </div>
            </div>
          </div>

        </div>

      </div>


{/* ---------------------------------------------------------------------------------------------------- */}
      <FaqSection />      


{/* ---------------------------------------------------------------------------------------------------- */}

      <div className='fullimage'>

      </div>
{/* ---------------------------------------------------------------------------------------------------- */}
      

    </div>
  );
};

export default FrontPage;